html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/**
 * Divider Bottom
 *
 * @param $p Padding for the bottom of the element
 * @param $m Margin for the bottom of the element
 */
/*
Put your global classes and global-like object rules here.
*/
*, *::after, *::before {
  box-sizing: border-box;
}

html,
body {
  position: relative;
}

html.overflow-hidden,
body.overflow-hidden {
  overflow: hidden;
}

.page {
  position: relative;
  z-index: 10;
  transition: top 0.1s;
}

.bottom-border {
  border-bottom: 0.2rem solid #e8e8e8;
}

.invisible {
  visibility: hidden;
  transition: visibility 0.3s;
}

.opacity-zero {
  opacity: 0;
  transition: opacity 0.3s;
}

.nice-heading, .nice-heading--flourish {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.nice-heading:before, .nice-heading--flourish:before {
  background-color: rgba(255, 255, 255, 0.25);
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
}

.nice-heading span, .nice-heading--flourish span {
  background-color: #3a424c;
  display: inline-block;
  padding: 0.4rem 3.5rem;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .nice-heading span, .nice-heading--flourish span {
    padding: 0.4rem 7rem;
  }
}

.nice-heading--flourish:before {
  background-color: rgba(58, 66, 76, 0.3);
  height: 4px;
  top: 47%;
}

.nice-heading--flourish .spacing {
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  width: 110px;
}

.nice-heading--flourish .block {
  background-color: #fff;
  border: 4px solid #1D181F;
  display: inline-block;
  height: 48px;
  position: relative;
  padding: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 48px;
}

.nice-heading--flourish .block .text {
  color: #1D181F;
  font-size: 1.6rem;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  -webkit-transform: rotate(-45deg) translateY(35%) translateX(30%);
          transform: rotate(-45deg) translateY(35%) translateX(30%);
}

.pad--vertical {
  padding-bottom: 40px;
  padding-top: 40px;
}

.pad--vertical--half {
  padding-bottom: 20px;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .pad--vertical {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.no-margin--right {
  margin-right: 0 !important;
}

.no-margin--bottom {
  margin-bottom: 0 !important;
}

.no-margin--top-bottom {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.section-divider {
  width: 100%;
  height: .2rem;
  background-color: #e8e8e8;
}

/*
The main wrapper for all parent elements. Mobile first.
Anything that does not span full document width, or has bleeds to the document edge uses this.
*/
.content__wrapper, .section--fp-feature__content, .discover-block, .discover, .explore, .post-header__content__wrapper, .gallery, .search, .search-header__content__wrapper, .team, .platform, .highlight, .section--demo__requires, .section--demo__example, .section--demo__example--full__header, .section--demo__usage {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  max-width: 88%;
  position: relative;
}

.content__wrapper::after, .section--fp-feature__content::after, .discover-block::after, .discover::after, .explore::after, .post-header__content__wrapper::after, .gallery::after, .search::after, .search-header__content__wrapper::after, .team::after, .platform::after, .highlight::after, .section--demo__requires::after, .section--demo__example::after, .section--demo__example--full__header::after, .section--demo__usage::after {
  clear: both;
  content: "";
  display: table;
}

@media screen and (min-width: 568px) {
  .content__wrapper, .section--fp-feature__content, .discover-block, .discover, .explore, .post-header__content__wrapper, .gallery, .search, .search-header__content__wrapper, .team, .platform, .highlight, .section--demo__requires, .section--demo__example, .section--demo__example--full__header, .section--demo__usage {
    max-width: 93%;
  }
}

@media screen and (min-width: 768px) {
  .content__wrapper, .section--fp-feature__content, .discover-block, .discover, .explore, .post-header__content__wrapper, .gallery, .search, .search-header__content__wrapper, .team, .platform, .highlight, .section--demo__requires, .section--demo__example, .section--demo__example--full__header, .section--demo__usage {
    max-width: 94%;
  }
}

@media screen and (min-width: 960px) {
  .content__wrapper, .section--fp-feature__content, .discover-block, .discover, .explore, .post-header__content__wrapper, .gallery, .search, .search-header__content__wrapper, .team, .platform, .highlight, .section--demo__requires, .section--demo__example, .section--demo__example--full__header, .section--demo__usage {
    max-width: 96%;
  }
}

@media screen and (min-width: 1320px) {
  .content__wrapper, .section--fp-feature__content, .discover-block, .discover, .explore, .post-header__content__wrapper, .gallery, .search, .search-header__content__wrapper, .team, .platform, .highlight, .section--demo__requires, .section--demo__example, .section--demo__example--full__header, .section--demo__usage {
    max-width: 1170px;
  }
}

.single-column {
  width: 90%;
  margin: 0 auto;
  max-width: 770px;
}

.begin-in-header {
  position: relative;
  top: -80px;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.2em;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 3.6rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.4rem;
}

h6 {
  font-size: 1.2rem;
}

p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2em;
}

li {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2em;
}

.wf-loading h1,
.wf-loading h2,
.wf-loading h3,
.wf-loading h4,
.wf-loading h5,
.wf-loading h6,
.wf-loading p,
.wf-loading li,
.wf-loading blockquote,
.wf-loading span,
.wf-loading input {
  visibility: hidden;
}

blockquote {
  font-family: "chaparral-pro", Georgia, "Times New Roman", serif;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: 400;
  font-style: italic;
  color: #242424;
  border-top: 0.3rem solid #242424;
  padding: 3rem 0;
  margin: 3rem 0 0 0;
}

blockquote i,
blockquote em {
  font-style: normal;
}

blockquote cite {
  display: block;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  line-height: 3.2rem;
  padding: 0 0 0 4.2rem;
  margin: 3rem 0 0 0;
  color: #242424;
  font-style: normal;
  background: transparent url("../images/quote.svg") no-repeat left center;
}

a {
  text-decoration: none;
  color: #242424;
  transition: color 0.3s;
}

a:hover {
  color: #f3732d;
}

.button {
  display: inline-block;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #3a424c;
  letter-spacing: 2px;
  font-weight: 700;
  background-color: transparent;
  padding: 1rem 2.8rem;
  margin: 0;
  border: 3px solid #3a424c;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
}

.button:hover {
  background-color: #3a424c;
  color: #ffffff;
  text-decoration: none;
}

.button:focus {
  outline: none;
}

.button:active {
  background-color: #505b69;
  border-color: #505b69;
}

.button:disabled, .button.disabled {
  background-color: transparent;
  color: #a3a3a3;
  border-color: #a3a3a3;
  cursor: not-allowed;
}

.button:disabled:hover, .button.disabled:hover {
  background-color: transparent;
  color: #a3a3a3;
}

.button.primary {
  border-color: #ff5629;
  color: #ff5629;
}

.button.primary:hover {
  background-color: #ff5629;
  color: #ffffff;
}

.button.primary:active {
  background-color: #f53400;
  border-color: #f53400;
}

.button.primary--solid {
  color: #ffffff;
  background-color: #ff5629;
  border-color: #ff5629;
}

.button.primary--solid:hover {
  background-color: #f53400;
  border-color: #f53400;
}

.button.secondary {
  border-color: #2c6e73;
  color: #2c6e73;
}

.button.secondary:hover {
  background-color: #2c6e73;
  color: #ffffff;
}

.button.secondary:active {
  background-color: #1e4b4e;
  border-color: #1e4b4e;
}

.button.large {
  font-size: 1.8rem;
  padding: 1.8rem 6rem;
}

.button--loadmore {
  width: 100%;
  text-transform: uppercase;
}

.button.solid {
  color: #ffffff;
  background-color: #2c6e73;
  border-color: #2c6e73;
}

.button.solid:hover {
  background-color: #1e4b4e;
  border-color: #1e4b4e;
}

.button.solid:active {
  background-color: #3a9198;
  border-color: #3a9198;
}

.button.solid:disabled, .button.solid.disabled {
  background-color: transparent;
  color: #a3a3a3;
  border-color: #a3a3a3;
  cursor: not-allowed;
}

.button.solid:disabled:hover, .button.solid:disabled:focus, .button.solid.disabled:hover, .button.solid.disabled:focus {
  background-color: transparent;
  color: #a3a3a3;
}

.button__small {
  padding: .5rem 1.4rem;
  font-size: 1.4rem;
}

/**
  * @name Buttons
  * @description Style: Default
  *
  * @requires _config.scss
  * @requires _mixins.scss
  * @requires _typography.scss
  * @requires _buttons.scss
  *
  * @markup
  * <button>Default Button</button>
  * <a class="button">Default Button Class</a>
  */
/**
  * @name Buttons
  * @description Style: Primary
  *
  * @requires _config.scss
  * @requires _mixins.scss
  * @requires _typography.scss
  * @requires _buttons.scss
  *
  * @state .primary - Displays a primary button
  *
  * @markup
  * <button class="primary">Primary Button</button>
  * <a class="button primary">Primary Button Class</a>
  */
/**
  * @name Buttons
  * @description Style: Secondary
  *
  * @requires _config.scss
  * @requires _mixins.scss
  * @requires _typography.scss
  * @requires _buttons.scss
  *
  * @state .secondary - Displays a secondary button
  *
  * @markup
  * <button class="secondary">Secondary Button</button>
  * <a class="button secondary">Secondary Button Class</a>
  */
/**
  * @name Buttons
  * @description Style: Solid
  *
  * @requires _config.scss
  * @requires _mixins.scss
  * @requires _typography.scss
  * @requires _buttons.scss
  *
  * @state .solid - Displays a solid button
  *
  * @markup
  * <button class="solid">Solid Button</button>
  * <a class="button solid">Solid Button Class</a>
  */
/**
  * @name Buttons
  * @description Sizes
  *
  * @requires _config.scss
  * @requires _mixins.scss
  * @requires _typography.scss
  * @requires _buttons.scss
  *
  * @state .large - Displays a large button
  *
  * @markup
  * <button>Default Button</button>
  * <button class="large">Large Button</button>
  * <a class="button">Default Button Class</a>
  * <a class="button large">Large Button Class</a>
  */
/**
  * @name Buttons
  * @description State: Disabled
  *
  * @requires _config.scss
  * @requires _mixins.scss
  * @requires _typography.scss
  * @requires _buttons.scss
  *
  * @state disabled - Displays a disabled button (applies to button elements)
  * @state .disabled - Displays a disabled button (applies to classed elements)
  *
  * @markup
  * <button disabled>Default Disabled Button</button>
  * <a class="button disabled">Default Disabled Button Class</a>
  * <button class="solid disabled">Solid Disabled Button</button>
  * <a class="button solid disabled">Solid Disabled Button Class</a>
  */
form {
  margin: 0;
  padding: 0;
}

input {
  /*&[type=text] {
      background: transparent;
      @include rem(border, 2px solid transparent);
      border-bottom-color: #979797;
      @include rem(margin, 10px 0);
      @include rem(padding, 10px);
      @include rem(min-width, 280px);
      outline: none;
      &:focus {
          outline: none;
          border-color: $secondary;
          border-width: 2px;
      }
      &.required {
          background: transparent url('../images/asterisk.svg') no-repeat right 10px;
      }
  }

  &[type=checkbox] {

  }

  &[type=radio] {

  }*/
}

textarea {
  background: transparent;
  border: 0.2rem solid transparent;
  border-bottom-color: #979797;
  margin: 1rem 0;
  padding: 1rem;
  min-width: 28rem;
  outline: none;
}

textarea:focus {
  outline: none;
  border-color: #0393f1;
  border-width: 2px;
}

.input {
  position: relative;
  margin-top: 2rem;
}

.input ul {
  list-style: none;
  margin: 1rem 0;
  padding: 0;
}

.input.textarea label {
  display: block;
  position: absolute;
  top: 1.5rem;
  transition: all 0.2s;
}

.input.textarea textarea {
  position: relative;
}

.input.textarea textarea:focus {
  transition: all .4s;
  -webkit-animation: grow-input-box 1s 1;
          animation: grow-input-box 1s 1;
}

.input.textarea textarea:focus + label {
  top: -2rem;
}

.input.text label {
  display: block;
  position: absolute;
  top: 1.5rem;
  transition: all 0.2s;
}

.input.text input {
  position: relative;
}

.input.text input:focus {
  transition: all .4s;
  -webkit-animation: grow-input-box 1s 1;
          animation: grow-input-box 1s 1;
}

.input.text input:focus + label {
  top: -2rem;
}

@-webkit-keyframes grow-input-box {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes grow-input-box {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flash-glow {
  0% {
    opacity: 0;
  }
  50% {
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash-glow {
  0% {
    opacity: 0;
  }
  50% {
  }
  100% {
    opacity: 1;
  }
}

.input.wrapped ul {
  overflow: hidden;
}

.input.wrapped li {
  float: left;
  padding-right: 1rem;
}

.input label {
  display: inline-block;
  vertical-align: middle;
  margin: 0.5rem;
  cursor: pointer;
  position: relative;
}

.input.radio input {
  opacity: 0;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  margin: 0.5rem;
  cursor: pointer;
}

.input.radio input:disabled + label {
  cursor: not-allowed;
  color: #b8b7b7;
}

.input.radio input:disabled + label:before {
  background-color: #babfc2;
  border-color: #babfc2;
}

.input.radio input + label:before {
  content: '';
  background: transparent;
  border: 2px solid #979797;
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  padding: 0.2rem;
  margin-right: 1rem;
  text-align: center;
  border-radius: 50%;
  transition: all 0.1s;
  position: relative;
  top: -0.1rem;
}

.input.radio input:checked + label:before {
  background-color: #0393F1;
  border-color: #0393F1;
  content: url(../images/radio_selected.svg);
  line-height: 1.2rem;
}

.input.checkbox input {
  opacity: 0;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  margin: 0.5rem;
  cursor: pointer;
}

.input.checkbox input:disabled + label {
  cursor: not-allowed;
  color: #b8b7b7;
}

.input.checkbox input:disabled + label:before {
  background-color: #babfc2;
  border-color: #babfc2;
}

.input.checkbox input + label:before {
  content: '';
  background: transparent;
  border: 0.2rem solid #979797;
  display: inline-block;
  vertical-align: middle;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  text-align: center;
  border-radius: 0.3rem;
  transition: all 0.1s;
  position: relative;
  top: -0.1rem;
}

.input.checkbox input + label:before:disabled {
  background-color: red;
}

.input.checkbox input:checked + label:before {
  background-color: #0393F1;
  border-color: #0393F1;
  content: url(../images/checkbox_checked.svg);
  line-height: 1.8rem;
}

::-webkit-input-placeholder {
  color: #848484;
}

:-moz-placeholder {
  color: #848484;
}

::-moz-placeholder {
  color: #848484;
}

:-ms-input-placeholder {
  color: #848484;
}

.modal {
  position: fixed;
  z-index: 500;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: scroll;
}

.modal__fill {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: -50%;
  left: -50%;
  border-radius: 100%;
  background: #ffffff;
}

.modal__fill--ghost {
  background: #ffffff;
}

.modal__fill--primary {
  background: #2c6e73;
}

.modal__content {
  position: relative;
  z-index: 501;
  width: 30%;
  max-width: 1170px;
  margin: 6rem auto 0;
}

.modal__content p {
  color: #ffffff;
}

.modal__close {
  position: fixed;
  top: 4rem;
  right: 2rem;
  cursor: pointer;
}

.modal__close__img {
  width: 3.6rem;
}

.modal__source {
  display: none;
}

[class^="svg-"], [class*=" svg-"] {
  display: inline-block;
  fill: currentColor;
  transition: fill 0.3s;
  height: auto;
}

.svg-search {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 2.125rem;
}

.svg-cancel {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 2.125rem;
}

.svg-calendar {
  width: 2.5rem;
  height: 2.5rem;
  opacity: 0.5;
}

.svg-linkedin {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.25rem;
  top: 0.2rem;
  position: relative;
}

.svg-logo {
  width: 9.6875rem;
  height: 1.55rem;
  margin-top: 2.2rem;
}

.svg-social-icon,
.svg-ui-icon {
  width: 2rem;
  height: 2rem;
  fill: #242424;
}

.svg-platform-logo {
  height: 2rem;
  fill: #ffffff;
}

.svg-product-logo {
  width: 60%;
  max-height: 3rem;
  margin-bottom: 3rem;
  fill: #242424;
  /*&.startups {
      a:hover & {
          fill: $primary;
      }
  }
  &.bizplan {
      a:hover & {
          fill: $bizplan;
      }
  }
  &.clarity {
      a:hover & {
          fill: $clarity;
      }
  }
  &.fundable {
      a:hover & {
          fill: $fundable;
      }
  }
  &.killerstartups {
      a:hover & {
          fill: $killerstartups;
      }
  }
  &.launchrock {
      a:hover & {
          fill: $launchrock;
      }
  }*/
}

a .svg-product-logo {
  transition: fill 0.2s;
}

.svg-product-logo-inline {
  width: 4rem;
  max-height: 1.6rem;
  fill: #ffffff;
  float: left;
  margin-right: 0.4rem;
}

.svg-product-logo-inline.inverse {
  fill: #101010;
}

.svg-product-logo-inline.tout {
  max-height: 2rem;
  width: 6rem;
  position: relative;
  top: -3px;
}

.svg-product-logo-properties {
  width: 8rem;
  max-height: 3rem;
  fill: #1c1c1c;
  display: inline-block;
  margin-right: 0.4rem;
}

@media screen and (min-width: 960px) {
  .svg-product-logo-properties {
    width: 12rem;
    max-height: 4rem;
  }
}

img.alignnone {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

img.aligncenter {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

img.alignleft {
  max-width: 50%;
  float: left;
  margin: 2rem 3rem 2rem 0;
}

img.alignright {
  max-width: 50%;
  float: right;
  margin: 2rem 0 2rem 3rem;
}

div.aligncenter {
  display: block;
  margin: 2rem auto;
}

div.aligncenter p.wp-caption-text {
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 80%;
}

.wp-caption {
  text-align: center;
}

.wp-caption p.wp-caption-text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 2rem auto;
  color: #8e8e8e;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

.wp-caption:after {
  content: "";
  display: table;
  clear: both;
}

.wp-caption.alignleft, .wp-caption.alignright {
  border-top: 0.3rem solid #000000;
}

.wp-caption.alignleft a img, .wp-caption.alignright a img {
  max-width: 100%;
  padding-top: 2rem;
}

@media screen and (min-width: 568px) {
  .wp-caption.alignleft a img, .wp-caption.alignright a img {
    padding-top: 0;
    position: relative;
    top: -.3rem;
    max-width: 50%;
  }
}

.wp-caption.alignleft p.wp-caption-text, .wp-caption.alignright p.wp-caption-text {
  text-align: left;
}

@media screen and (min-width: 568px) {
  .wp-caption.alignleft a img {
    float: left;
    margin: 0 2rem 2rem 0;
  }
}

@media screen and (min-width: 568px) {
  .wp-caption.alignright a img {
    float: right;
    margin: 0 0 2rem 2rem;
  }
}

.wp-caption.alignnone {
  display: block;
  margin: 2rem auto;
}

.wp-caption.alignnone p.wp-caption-text {
  display: block;
  max-width: 100%;
  text-align: left;
}

.card, .cards__g1111 .card, .cards__g112 .card, .cards__g121 .card, .cards__g211 .card, .cards__g22 .card {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.card:last-child {
  margin-right: 0;
}

.card__image {
  overflow: hidden;
  text-align: center;
  height: 22rem;
  border-radius: 0.5rem;
  margin: 0 auto 1rem;
}

.card__image img {
  margin: 0 -100%;
}

.card__header {
  font-size: 1.8rem;
  margin: 2rem 0 0;
}

.card__detail {
  font-size: 1.6rem;
  margin-top: 1.2rem;
}

.cards, .cards__g1111, .cards__g112, .cards__g121, .cards__g211, .cards__g22 {
  display: block;
  margin-bottom: 3rem;
}

.cards::after, .cards__g1111::after, .cards__g112::after, .cards__g121::after, .cards__g211::after, .cards__g22::after {
  clear: both;
  content: "";
  display: table;
}

.cards__title {
  margin-bottom: 2rem;
  overflow: hidden;
}

.cards__title--bordered {
  border-bottom: 0.2rem solid #e8e8e8;
  margin-bottom: 4rem;
}

.cards__title__header {
  font-size: 2rem;
  line-height: 2.8rem;
  margin: 0 0 2rem 0;
  padding-right: 3rem;
  padding: 0;
  width: 100%;
}

.cards__title__arrow {
  float: right;
  top: 0.5rem;
  position: relative;
  transition: fill 0.3s;
}

.cards__title a {
  display: block;
  overflow: hidden;
  transition: color 0.3s;
  color: #242424;
}

.cards__title a:hover {
  color: #f3732d;
}

.cards__title a:hover .cards__title__arrow {
  fill: #f3732d;
}

@media screen and (min-width: 568px) {
  .cards__g1111 .card {
    float: left;
    display: block;
    margin-right: 3.57866%;
    width: 48.21067%;
  }
  .cards__g1111 .card:last-child {
    margin-right: 0;
  }
  .cards__g1111 .card:nth-child(2n) {
    margin-right: 0;
  }
  .cards__g1111 .card:nth-child(2n+1) {
    clear: left;
  }
}

@media screen and (min-width: 768px) {
  .cards__g1111 .card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .cards__g1111 .card:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .cards__g1111 .card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .cards__g1111 .card:nth-child(2n) {
    margin-right: 2.35765%;
  }
  .cards__g1111 .card:nth-child(2n+1) {
    clear: none;
  }
  .cards__g1111 .card:last-child {
    margin-right: 0;
  }
  .cards__g1111 .card:nth-child(4n) {
    margin-right: 0;
  }
  .cards__g1111 .card:nth-child(4n+1) {
    clear: left;
  }
}

@media screen and (min-width: 568px) {
  .cards__g112 .card {
    float: left;
    display: block;
    margin-right: 3.57866%;
    width: 100%;
  }
  .cards__g112 .card:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .cards__g112 .card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .cards__g112 .card:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .cards__g112 .card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .cards__g112 .card:last-child {
    margin-right: 0;
  }
  .cards__g112 .card:nth-of-type(3) {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .cards__g112 .card:nth-of-type(3):last-child {
    margin-right: 0;
  }
  .cards__g112 .card:nth-of-type(3) .card__header {
    font-weight: 700;
    font-size: 3rem;
  }
  .cards__g112 .card:nth-of-type(3) .card__detail {
    font-size: 2.1rem;
    line-height: 3rem;
  }
}

@media screen and (min-width: 568px) {
  .cards__g121 .card {
    float: left;
    display: block;
    margin-right: 3.57866%;
    width: 100%;
  }
  .cards__g121 .card:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .cards__g121 .card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .cards__g121 .card:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .cards__g121 .card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .cards__g121 .card:last-child {
    margin-right: 0;
  }
  .cards__g121 .card:nth-of-type(2) {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .cards__g121 .card:nth-of-type(2):last-child {
    margin-right: 0;
  }
  .cards__g121 .card:nth-of-type(2) .card__header {
    font-weight: 700;
    font-size: 3rem;
  }
  .cards__g121 .card:nth-of-type(2) .card__detail {
    font-size: 2.1rem;
    line-height: 3rem;
  }
}

@media screen and (min-width: 568px) {
  .cards__g211 .card {
    float: left;
    display: block;
    margin-right: 3.57866%;
    width: 100%;
  }
  .cards__g211 .card:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .cards__g211 .card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .cards__g211 .card:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .cards__g211 .card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .cards__g211 .card:last-child {
    margin-right: 0;
  }
  .cards__g211 .card:nth-of-type(1) {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .cards__g211 .card:nth-of-type(1):last-child {
    margin-right: 0;
  }
  .cards__g211 .card:nth-of-type(1) .card__header {
    font-weight: 700;
    font-size: 3rem;
  }
  .cards__g211 .card:nth-of-type(1) .card__detail {
    font-size: 2.1rem;
    line-height: 3rem;
  }
}

@media screen and (min-width: 568px) {
  .cards__g22 .card {
    float: left;
    display: block;
    margin-right: 3.57866%;
    width: 100%;
  }
  .cards__g22 .card:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .cards__g22 .card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .cards__g22 .card:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .cards__g22 .card .card__header {
    font-weight: 700;
    font-size: 3rem;
  }
  .cards__g22 .card .card__detail {
    font-size: 2.1rem;
    line-height: 3rem;
  }
}

.section--fp-feature__content {
  border-top: 0.6rem solid #242424;
  display: block;
}

.section--fp-feature__content::after {
  clear: both;
  content: "";
  display: table;
}

@media screen and (min-width: 768px) {
  .section--fp-feature__content {
    padding-top: 6rem;
  }
}

.section--fp-feature__content__image {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  height: 23rem;
  margin: 1.5rem auto;
}

.section--fp-feature__content__image:last-child {
  margin-right: 0;
}

.section--fp-feature__content__image img {
  margin: 0 -100%;
  position: relative;
  top: -50%;
}

@media screen and (min-width: 768px) {
  .section--fp-feature__content__image {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    margin-left: 51.17883%;
    position: relative;
    top: -6.6rem;
    height: 70rem;
    left: 0.1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .section--fp-feature__content__image:last-child {
    margin-right: 0;
  }
  .section--fp-feature__content__image img {
    top: auto;
  }
}

@media screen and (min-width: 960px) {
  .section--fp-feature__content__image {
    height: 50rem;
  }
}

.section--fp-feature__content__detail {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.section--fp-feature__content__detail:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .section--fp-feature__content__detail {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    margin-left: -102.35765%;
  }
  .section--fp-feature__content__detail:last-child {
    margin-right: 0;
  }
}

.section--fp-feature__content__detail__type {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  padding: 0;
  margin: 0;
  color: #242424;
}

.section--fp-feature__content__detail__header {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.7rem;
  margin: 2.6rem 0;
  color: #242424;
}

@media screen and (min-width: 768px) {
  .section--fp-feature__content__detail__header {
    font-size: 4.8rem;
    line-height: 6rem;
    margin: 2rem 0;
  }
}

.section--fp-feature__content__detail__header a {
  color: #242424;
  text-decoration: none;
  transition: color 0.3s;
}

.section--fp-feature__content__detail__header a:hover {
  color: #f3732d;
}

.section--fp-feature__content__detail__excerpt {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 0;
  margin: 0;
  color: #5c5c5c;
}

@media screen and (min-width: 768px) {
  .section--fp-feature__content__detail__excerpt {
    font-size: 2.1rem;
    line-height: 3.6rem;
  }
}

.discover-block {
  display: block;
  padding: 6rem 0 3rem;
}

.discover-block::after {
  clear: both;
  content: "";
  display: table;
}

.discover-block__divider--bottom {
  border-bottom: 0.2rem solid #e8e8e8;
  padding-bottom: 6rem;
  margin-bottom: 6rem;
}

.discover-block__image {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  height: 23rem;
  border-radius: 0.5rem;
  margin: 1.5rem auto;
  box-shadow: 0rem 0rem 1.5rem 0.2rem rgba(0, 0, 0, 0.25);
}

.discover-block__image:last-child {
  margin-right: 0;
}

.discover-block__image img {
  margin: 0 -100%;
  position: relative;
  top: -50%;
}

@media screen and (min-width: 768px) {
  .discover-block__image {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    margin-left: 51.17883%;
    height: 50rem;
    left: 0.1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .discover-block__image:last-child {
    margin-right: 0;
  }
  .discover-block__image img {
    top: 0;
  }
}

@media screen and (min-width: 960px) {
  .discover-block__image {
    height: 33rem;
  }
  .discover-block__image img {
    top: -10%;
  }
}

@media screen and (min-width: 768px) {
  .discover-block__image--flipped {
    margin-left: 0%;
    left: 0;
  }
}

.discover-block__detail {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.discover-block__detail:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .discover-block__detail {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    margin-left: -102.35765%;
    padding-right: 4rem;
  }
  .discover-block__detail:last-child {
    margin-right: 0;
  }
}

.discover-block__detail__type {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #242424;
}

@media screen and (min-width: 768px) {
  .discover-block__detail__type {
    font-size: 2rem;
  }
}

.discover-block__detail__header {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.4rem;
  margin: 1rem 0;
  color: #242424;
}

@media screen and (min-width: 768px) {
  .discover-block__detail__header {
    font-size: 3.6rem;
    line-height: 4.4rem;
    margin: 2rem 0;
  }
}

.discover-block__detail__header a {
  color: #242424;
  text-decoration: none;
  transition: color 0.3s;
}

.discover-block__detail__header a:hover {
  color: #f3732d;
}

.discover-block__detail__excerpt {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3rem;
  padding: 0;
  margin: 0;
  color: #5c5c5c;
}

@media screen and (min-width: 768px) {
  .discover-block__detail__excerpt {
    font-size: 2.1rem;
    line-height: 3.6rem;
  }
}

.discover-block__detail__button {
  margin-top: 2rem;
}

@media screen and (min-width: 768px) {
  .discover-block__detail__button {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 768px) {
  .discover-block__detail--flipped {
    padding-right: 0;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    margin-left: 0%;
    padding-left: 4rem;
  }
  .discover-block__detail--flipped:last-child {
    margin-right: 0;
  }
}

.qotd {
  padding: 3rem;
  border-radius: 0.5rem;
  background-color: #f3f3f3;
}

.qotd p {
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  line-height: 3rem;
  color: #242424;
}

@media screen and (min-width: 1024px) {
  .qotd p {
    font-size: 3rem;
    line-height: 4.2rem;
  }
}

.qotd p.title {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #242424;
}

@media screen and (min-width: 1024px) {
  .qotd p.title {
    font-size: 2rem;
  }
}

.qotd time {
  display: block;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  padding: 1rem 0 3rem 0;
  color: #4a4a4a;
}

@media screen and (min-width: 1024px) {
  .qotd time {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .qotd {
    max-height: 46rem;
    overflow: hidden;
  }
}

.qotd .button {
  margin-top: 2rem;
}

@media screen and (min-width: 1024px) {
  .qotd .button {
    margin-top: 3rem;
  }
}

.footer {
  position: relative;
}

.footer__content {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 90%;
  padding: 0 1rem;
}

.footer__content::after {
  clear: both;
  content: "";
  display: table;
}

@media screen and (min-width: 568px) {
  .footer__content {
    max-width: 92%;
  }
}

@media screen and (min-width: 768px) {
  .footer__content {
    max-width: 94%;
  }
}

@media screen and (min-width: 1320px) {
  .footer__content {
    max-width: 1170px;
    padding: 0 2rem;
  }
}

.footer--site {
  background-color: #242424;
  padding: 3rem 0;
}

@media screen and (min-width: 768px) {
  .footer--site {
    padding: 10rem 0;
  }
}

.footer--site nav {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}

.footer--site nav:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .footer--site nav {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    text-align: left;
  }
  .footer--site nav:last-child {
    margin-right: 0;
  }
  .footer--site nav:nth-child(3n) {
    margin-right: 0;
  }
  .footer--site nav:nth-child(3n+1) {
    clear: left;
  }
}

@media screen and (min-width: 960px) {
  .footer--site nav {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
    margin-bottom: 0;
  }
  .footer--site nav:nth-child(3n) {
    margin-right: 2.35765%;
  }
  .footer--site nav:nth-child(3n+1) {
    clear: none;
  }
  .footer--site nav:last-child {
    margin-right: 0;
  }
  .footer--site nav:last-child {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .footer--site nav:last-child:last-child {
    margin-right: 0;
  }
}

.footer--site nav h4 {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 3.6rem;
  letter-spacing: 0.257rem;
  color: #ffffff;
  text-transform: uppercase;
}

.footer--site nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer--site nav ul li {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 3.6rem;
  color: #a0a0a0;
}

.footer--site nav ul li a {
  color: #a0a0a0;
  text-decoration: none;
  transition: color 0.3s;
}

.footer--site nav ul li a:hover {
  color: #0393f1;
}

.footer--site nav.social li {
  display: inline-block;
  margin-right: 1.5rem;
}

.footer--site nav.social li:last-child {
  margin-right: 0;
}

.footer--site nav.social li a .svg-social-icon {
  fill: #ffffff;
  transition: fill 0.3s;
  width: 2.2rem;
  height: 2.2rem;
}

.footer--site nav.social li a:hover .svg-social-icon {
  fill: #0393f1;
}

.footer--platform {
  color: #898989;
  background-color: #181818;
  padding: 3rem 0;
}

@media screen and (min-width: 768px) {
  .footer--platform {
    padding: 4rem 0;
  }
}

.footer--platform__colophon {
  text-align: center;
  padding-top: 2rem;
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.footer--platform__colophon:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .footer--platform__colophon {
    text-align: left;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  }
  .footer--platform__colophon:last-child {
    margin-right: 0;
  }
}

.footer--platform .col {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  text-align: center;
  padding-top: 2rem;
}

.footer--platform .col:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .footer--platform .col {
    text-align: left;
    padding-top: 0;
    float: left;
    display: block;
    margin-right: 4.11092%;
    width: 10.76207%;
  }
  .footer--platform .col:last-child {
    margin-right: 0;
  }
  .footer--platform .col:first-child {
    float: left;
    display: block;
    margin-right: 4.11092%;
    width: 25.63506%;
  }
  .footer--platform .col:first-child:last-child {
    margin-right: 0;
  }
}

.footer--platform p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  padding: 0;
}

.footer--platform a {
  color: #898989;
}

.footer--platform a:hover {
  color: #0393f1;
}

.footer--platform a:hover .svg-platform-logo {
  fill: #0393f1;
}

.footer--platform img {
  max-width: 100%;
}

.footer--platform .svg-platform-logo {
  position: relative;
}

article p {
  font-size: 2rem;
  line-height: 3rem;
  margin: 0 0 2rem 0;
  padding: 0;
  color: #5c5c5c;
}

@media screen and (min-width: 768px) {
  article p {
    font-size: 2rem;
    line-height: 4.8rem;
    margin: 0 0 3rem 0;
  }
}

article a {
  color: #242424;
  text-decoration: none;
  transition: color 0.3s;
}

article a:hover {
  color: #f3732d;
}

.discover {
  display: block;
  margin-bottom: 6rem;
}

.discover::after {
  clear: both;
  content: "";
  display: table;
}

.discover__header {
  border-top: 0.6rem solid #242424;
  margin-bottom: 8rem;
  border-bottom: .2rem solid #e8e8e8;
}

.discover__header:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 768px) {
  .discover__header {
    padding-top: 6rem;
  }
}

.discover__header__image {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  height: 23rem;
  margin: 1.5rem auto;
}

.discover__header__image:last-child {
  margin-right: 0;
}

.discover__header__image img {
  margin: 0 -100%;
  position: relative;
  top: -50%;
}

@media screen and (min-width: 768px) {
  .discover__header__image {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    margin-left: 51.17883%;
    position: relative;
    top: -6.6rem;
    height: 70rem;
    left: 0.1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .discover__header__image:last-child {
    margin-right: 0;
  }
  .discover__header__image img {
    top: auto;
  }
}

@media screen and (min-width: 960px) {
  .discover__header__image {
    height: 50rem;
  }
}

.discover__header__detail {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.discover__header__detail:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .discover__header__detail {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    margin-left: -102.35765%;
  }
  .discover__header__detail:last-child {
    margin-right: 0;
  }
}

.discover__header__detail__type {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  padding: 0;
  margin: 0;
  color: #242424;
}

.discover__header__detail__header {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.7rem;
  margin: 2.6rem 0;
  color: #242424;
}

@media screen and (min-width: 768px) {
  .discover__header__detail__header {
    font-size: 4.8rem;
    line-height: 6rem;
    margin: 2rem 0;
  }
}

.discover__header__detail__header a {
  color: #242424;
  text-decoration: none;
  transition: color 0.3s;
}

.discover__header__detail__header a:hover {
  color: #f3732d;
}

.discover__header__detail__byline {
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.8rem;
  padding: 0;
  margin: 0;
  color: #242424;
}

.discover__header__detail__share-list {
  list-style: none;
  margin: 4rem 0;
  padding: 0;
}

.discover__header__detail__share-list li {
  display: inline-block;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  margin-right: 1rem;
  color: #5c5c5c;
}

.discover__header__detail__share-list li a {
  background: transparent no-repeat center left;
  text-decoration: none;
  line-height: 2rem;
  color: #5c5c5c;
  display: block;
}

.discover__header__detail__share-list li a .svg-social-icon {
  top: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
  transition: fill 0.3s;
}

.discover__header__detail__share-list li a:hover {
  color: #f3732d;
}

.discover__header__detail__share-list li a:hover .svg-social-icon {
  fill: #f3732d;
}

.discover__content {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.discover__content:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .discover__content {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    margin-left: 8.5298%;
  }
  .discover__content:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .discover__content {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    margin-left: 17.05961%;
  }
  .discover__content:last-child {
    margin-right: 0;
  }
}

.discover__content__intro {
  font-size: 2.8rem;
}

.explore {
  display: block;
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.explore::after {
  clear: both;
  content: "";
  display: table;
}

.explore__header {
  border-top: 0.6rem solid #242424;
  margin-bottom: 8rem;
  border-bottom: .2rem solid #e8e8e8;
}

.explore__header:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 768px) {
  .explore__header {
    padding-top: 6rem;
  }
}

.explore__header__image {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  height: 23rem;
  margin: 1.5rem auto;
}

.explore__header__image:last-child {
  margin-right: 0;
}

.explore__header__image img {
  margin: 0 -100%;
  position: relative;
  top: -50%;
}

@media screen and (min-width: 768px) {
  .explore__header__image {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    margin-left: 51.17883%;
    position: relative;
    top: -6.6rem;
    height: 70rem;
    left: 0.1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .explore__header__image:last-child {
    margin-right: 0;
  }
  .explore__header__image img {
    top: auto;
  }
}

@media screen and (min-width: 960px) {
  .explore__header__image {
    height: 50rem;
  }
}

.explore__header__detail {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.explore__header__detail:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .explore__header__detail {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    margin-left: -102.35765%;
  }
  .explore__header__detail:last-child {
    margin-right: 0;
  }
}

.explore__header__detail__type {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  padding: 0;
  margin: 0;
  color: #242424;
}

.explore__header__detail__header {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.7rem;
  margin: 2.6rem 0;
  color: #242424;
}

@media screen and (min-width: 768px) {
  .explore__header__detail__header {
    font-size: 4.8rem;
    line-height: 6rem;
    margin: 2rem 0;
  }
}

.explore__header__detail__header a {
  color: #242424;
  text-decoration: none;
  transition: color 0.3s;
}

.explore__header__detail__header a:hover {
  color: #f3732d;
}

.explore__header__detail__byline {
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.8rem;
  padding: 0;
  margin: 0;
  color: #242424;
}

.explore__header__detail__share-list {
  list-style: none;
  margin: 4rem 0;
  padding: 0;
}

.explore__header__detail__share-list li {
  display: inline-block;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  margin-right: 1rem;
  color: #5c5c5c;
}

.explore__header__detail__share-list li a {
  background: transparent no-repeat center left;
  text-decoration: none;
  line-height: 2rem;
  color: #5c5c5c;
  display: block;
}

.explore__header__detail__share-list li a .svg-social-icon {
  top: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
  transition: fill 0.3s;
}

.explore__header__detail__share-list li a:hover {
  color: #f3732d;
}

.explore__header__detail__share-list li a:hover .svg-social-icon {
  fill: #f3732d;
}

.explore__content {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.explore__content:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .explore__content {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    margin-left: 8.5298%;
  }
  .explore__content:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .explore__content {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    margin-left: 17.05961%;
  }
  .explore__content:last-child {
    margin-right: 0;
  }
}

.explore__content__intro {
  font-size: 2.8rem;
}

.platformbar {
  display: none;
}

@media screen and (min-width: 768px) {
  .platformbar {
    display: block;
    z-index: 40;
    position: fixed;
    min-height: 35px;
    width: 100%;
    background-color: #242424;
    color: #ffffff;
    clear: both;
  }
  .platformbar span {
    float: left;
    display: block;
    padding-right: 1.5rem;
    border-right: 1px solid rgba(151, 151, 151, 0.35);
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: .7rem;
    font-weight: 400;
    color: #b6b6b6;
    position: relative;
    top: -1px;
  }
  .platformbar span strong {
    color: #ffffff;
    font-weight: 400;
    font-size: .9rem;
  }
  .platformbar ul {
    list-style: none;
    float: left;
    margin: 0;
    padding: 0;
  }
  .platformbar ul li {
    float: left;
    margin-left: 1.5rem;
  }
}

.site-header {
  width: 100%;
  z-index: 40;
}

.site-header__platform {
  display: none;
}

@media screen and (min-width: 768px) {
  .site-header__platform {
    display: block;
    z-index: 41;
    transition: all 0.3s;
    height: 3.5rem;
    width: 100%;
    background-color: #242424;
    color: #ffffff;
    overflow: hidden;
  }
  .site-header__platform__logo {
    cursor: pointer;
    float: left;
    display: block;
    padding-right: 1.5rem;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    color: #b6b6b6;
    position: relative;
    top: -.1rem;
    line-height: 3.5rem;
  }
  .site-header__platform__logo strong {
    color: #ffffff;
    font-weight: 400;
    font-size: 1.4rem;
  }
  .site-header__platform__news, .site-header__platform__links {
    color: #bababa;
    font-size: 1.2rem;
    font-weight: 400;
    float: right;
    line-height: 3.5rem;
  }
  .site-header__platform__news a, .site-header__platform__links a {
    color: #bababa;
    margin-right: 2rem;
  }
  .site-header__platform__news a:hover, .site-header__platform__links a:hover {
    color: #0393f1;
  }
  .site-header__platform__news a:last-child, .site-header__platform__links a:last-child {
    margin-right: 0;
  }
  .site-header__platform__news {
    padding-right: 1.5rem;
    border-right: 0.1rem solid rgba(151, 151, 151, 0.35);
    margin-right: 1.5rem;
  }
}

.site-header__platform-nav {
  display: none;
  width: 100%;
  background-color: #242424;
  border-top: 0.1rem solid rgba(151, 151, 151, 0.35);
  padding: 3rem 0;
}

@media screen and (min-width: 768px) {
  .site-header__platform-nav {
    padding: 4rem 0;
  }
}

.site-header__platform-nav .col {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  text-align: center;
}

.site-header__platform-nav .col:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .site-header__platform-nav .col {
    text-align: left;
    float: left;
    display: block;
    margin-right: 5.85151%;
    width: 15.3188%;
  }
  .site-header__platform-nav .col:last-child {
    margin-right: 0;
  }
}

.site-header__platform-nav p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  padding: 0;
  position: relative;
  color: #b6b6b6;
  top: -.5rem;
}

.site-header__platform-nav a {
  color: #898989;
}

.site-header__platform-nav a:hover {
  color: #0393f1;
}

.site-header__platform-nav a:hover .svg-platform-logo {
  fill: #0393f1;
}

.site-header__platform-nav img {
  max-width: 100%;
  height: 5rem;
}

.site-header__platform-nav .svg-platform-logo {
  position: relative;
}

.site-header__mobile__navigation {
  display: none;
  width: 100%;
  background-color: #f3732d;
  z-index: 42;
  position: fixed;
  padding-top: 8rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  text-align: center;
}

.site-header__mobile__navigation__list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.site-header__mobile__navigation__list__item:last-child {
  border-bottom: none;
}

.site-header__mobile__navigation__list__item a {
  display: block;
  text-transform: lowercase;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #ffffff;
  background-color: transparent;
  padding: 1rem;
}

.site-header__mobile__navigation__list__item a span {
  display: block;
  font-weight: 600;
  font-size: 2rem;
  color: #ffffff;
  position: relative;
  padding-bottom: .5rem;
}

.site-header__mobile__navigation__list__item a.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.site-header .menu__opener {
  width: 3.6rem;
  height: 3.6rem;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 3.6rem;
  margin-top: 2rem;
  margin-right: .8rem;
  position: relative;
  display: inline-block;
  float: right;
  background-image: url("/assets/images/ui/ic_menu_black_24px.svg");
}

.site-header .menu__opener.front {
  background-image: url("/assets/images/ui/ic_menu_white_24px.svg");
}

__site--main {
  background-color: #efefef;
  z-index: 41;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  width: 100%;
}

@media screen and (min-width: 768px) {
  __site--main {
    top: 3.5rem;
  }
}

.arrow {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  top: .5rem;
  left: .5rem;
  width: 1.2rem;
  height: 1.2rem;
  display: inline-block;
  position: relative;
  transition: 0.4s ease;
}

.arrow:before, .arrow:after {
  background-color: transparent;
  width: .2rem;
  height: .4rem;
  display: inline-block;
  position: absolute;
  border-bottom: .7rem solid #ffffff;
  top: 0;
  left: 0;
  content: "";
  transition: 0.4s ease;
}

.arrow:before {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  left: .1rem;
}

.arrow:after {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  left: .5rem;
}

.active .arrow:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.active .arrow:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.site-header__main-nav {
  background-color: transparent;
  position: absolute;
  transition: all 0.3s;
  width: 100%;
}

.site-header__main-nav__branding {
  position: absolute;
  text-align: center;
  top: -10px;
  transition: all 0.3s;
  width: 100%;
  z-index: 1;
}

.site-header__main-nav__list {
  display: none;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

@media screen and (min-width: 768px) {
  .site-header__main-nav__list {
    display: block;
  }
}

.site-header__main-nav__list:after {
  content: "";
  display: table;
  clear: both;
}

.site-header__main-nav__list-item, .site-header__main-nav__list-item--utility {
  display: inline-block;
  font-size: 1.3rem;
  margin-right: 3rem;
  position: relative;
  z-index: 2;
}

.site-header__main-nav__list-item a, .site-header__main-nav__list-item--utility a {
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-weight: 700;
  letter-spacing: 1.73px;
  padding: 5px 0;
  transition: color 0.3s;
  text-transform: uppercase;
}

.site-header__main-nav__list-item a:hover, .site-header__main-nav__list-item--utility a:hover {
  border-bottom: 2px solid #ff5629;
  margin-bottom: -2px;
}

.site-header__main-nav__list-item a.active, .site-header__main-nav__list-item--utility a.active {
  border-bottom: 2px solid #ff5629;
  margin-bottom: -2px;
}

.site-header__main-nav__list-item--utility {
  float: right;
}

.site-header__main-nav__search {
  padding: 5px 0;
}

.site-header__main-nav .svg-search,
.site-header__main-nav .svg-cancel {
  color: #ffffff;
  height: 2.4rem;
  margin-top: 0;
  width: 2.4rem;
  vertical-align: middle;
}

.site-header__main-nav .menu-open {
  background: url("/assets/images/ui/ic_menu_white_24px.svg") no-repeat top center;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 2.6rem;
  margin-top: 5px;
  position: relative;
  width: 2.6rem;
  z-index: 11;
}

@media screen and (min-width: 768px) {
  .site-header__main-nav .menu-open {
    display: none;
  }
}

.modal__content .site-header__main-nav__list-item, .modal__content .site-header__main-nav__list-item--utility {
  display: block;
  float: none;
  margin-right: 0;
  text-align: center;
}

.modal__content .svg-search,
.modal__content .svg-cancel {
  color: #ffffff;
  height: 2.4rem;
  margin-top: 0;
  width: 2.4rem;
  vertical-align: middle;
}

/* Big, bold page titles. These guys reside within a parent .hero  */
.page-header {
  text-align: center;
}

.page-header__title {
  color: #ffffff;
  display: inline-block;
  font-size: 7.26rem;
  font-weight: 900;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .page-header__title {
    font-size: 11.0rem;
  }
}

.page-header__title--with-tag {
  display: block;
}

.page-header__title__tag {
  display: block;
  float: right;
}

.page-header__summary {
  color: #ffffff;
  font-size: 1.98rem;
  font-weight: 300;
  opacity: 0.75;
  margin: 0 auto;
  max-width: 50%;
}

@media (max-width: 568px) {
  .page-header__summary {
    line-height: 1;
    max-width: 80%;
  }
}

@media screen and (min-width: 768px) {
  .page-header__summary {
    font-size: 3rem;
  }
}

.post-header {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.post-header__content__wrapper__divider-bottom {
  border-bottom: 0.2rem solid #e8e8e8;
  padding-bottom: 0;
  margin-bottom: 3rem;
}

.post-header__col {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.post-header__col:last-child {
  margin-right: 0;
}

.post-header__col--cta__image {
  position: relative;
  top: -3rem;
}

@media screen and (min-width: 768px) {
  .post-header__col--title {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
  }
  .post-header__col--title:last-child {
    margin-right: 0;
  }
  .post-header__col--cta {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    position: relative;
  }
  .post-header__col--cta:last-child {
    margin-right: 0;
  }
  .post-header__col--cta__text {
    padding: 9rem 0 .5rem 0;
    font-weight: 600;
    font-size: 2rem;
    color: #242424;
    line-height: 2.4rem;
    margin: 0;
  }
  .post-header__col--cta__image {
    padding-top: 13rem;
    top: 0;
  }
}

.post-header__meta {
  font-weight: 600;
  color: #242424;
  margin: 0 0 1rem 0;
  font-size: 2rem;
  line-height: 2.8rem;
  padding: 0;
}

.post-header__title {
  font-weight: 700;
  color: #242424;
  margin: 0;
  font-size: 7.2rem;
  line-height: 7.2rem;
  letter-spacing: -.25rem;
  padding: 0;
}

/*
Instrinsic div mixin (modified a bit for our needs) - helpful articles explaining the concept noted here
https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
http://blog.learningspaces.io/flexible-cover-images-using-intrinsic-ratio/

@params: $bgColor, $imgUrl, $elementTopOffset

For our needs, we need to use backgrounds with transparency to acheive the "colorized" effect.
The .intrinsic .element is locked in to a 16x9 format, so, as long as our exported images meet that criteria, they'll look great.

*/
/*
TODO: All the padding-bottom values depend on child elements .page-header__summary and .nice-heading
Come up with a way to handle padding-bottom of the .hero when these objects are present.
*/
.hero, .hero--texture-waveform, .hero--texture-cubic, .hero--texture-ribbon {
  position: relative;
}

.hero--texture-waveform {
  background-color: #3a424c;
  background-image: url("/assets/images/ui/hero_texture_waveform.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero--texture-waveform .intrinsic {
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
}

@media (max-width: 568px) {
  .hero--texture-waveform .intrinsic {
    padding-bottom: 96.25%;
  }
}

@media screen and (min-width: 1260px) {
  .hero--texture-waveform .intrinsic {
    padding-bottom: 36.25%;
  }
}

@media screen and (min-width: 2000px) {
  .hero--texture-waveform .intrinsic {
    padding-bottom: 22.25%;
  }
}

.hero--texture-waveform .intrinsic .element {
  left: 0;
  position: absolute;
  top: 25%;
  width: 100%;
}

.hero--texture-cubic {
  background-color: #3a424c;
  background-image: url("/assets/images/ui/hero_texture_cubic.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero--texture-cubic .intrinsic {
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
}

@media (max-width: 568px) {
  .hero--texture-cubic .intrinsic {
    padding-bottom: 96.25%;
  }
}

@media screen and (min-width: 1260px) {
  .hero--texture-cubic .intrinsic {
    padding-bottom: 36.25%;
  }
}

@media screen and (min-width: 2000px) {
  .hero--texture-cubic .intrinsic {
    padding-bottom: 22.25%;
  }
}

.hero--texture-cubic .intrinsic .element {
  left: 0;
  position: absolute;
  top: 25%;
  width: 100%;
}

.hero--texture-ribbon {
  background-color: #3a424c;
  background-image: url("/assets/images/ui/hero_texture_ribbon.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero--texture-ribbon .intrinsic {
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
}

@media (max-width: 568px) {
  .hero--texture-ribbon .intrinsic {
    padding-bottom: 96.25%;
  }
}

@media screen and (min-width: 1260px) {
  .hero--texture-ribbon .intrinsic {
    padding-bottom: 36.25%;
  }
}

@media screen and (min-width: 2000px) {
  .hero--texture-ribbon .intrinsic {
    padding-bottom: 22.25%;
  }
}

.hero--texture-ribbon .intrinsic .element {
  left: 0;
  position: absolute;
  top: 25%;
  width: 100%;
}

.hero .qa-block, .hero--texture-waveform .qa-block, .hero--texture-cubic .qa-block, .hero--texture-ribbon .qa-block {
  padding-top: 13rem;
  padding-bottom: 8rem;
}

.hero .qa-block .qa-block__question__title--inverse, .hero--texture-waveform .qa-block .qa-block__question__title--inverse, .hero--texture-cubic .qa-block .qa-block__question__title--inverse, .hero--texture-ribbon .qa-block .qa-block__question__title--inverse {
  font-size: 1.98rem;
  font-weight: 700;
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .hero .qa-block .qa-block__question__title--inverse, .hero--texture-waveform .qa-block .qa-block__question__title--inverse, .hero--texture-cubic .qa-block .qa-block__question__title--inverse, .hero--texture-ribbon .qa-block .qa-block__question__title--inverse {
    font-size: 3.0rem;
  }
}

.hero .qa-block .summary, .hero--texture-waveform .qa-block .summary, .hero--texture-cubic .qa-block .summary, .hero--texture-ribbon .qa-block .summary {
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.584rem;
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .hero .qa-block .summary, .hero--texture-waveform .qa-block .summary, .hero--texture-cubic .qa-block .summary, .hero--texture-ribbon .qa-block .summary {
    font-size: 2.4rem;
  }
}

.loadmore {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #3a424c;
  text-align: center;
  padding: 0;
  margin: 40px 0 0 0;
}

.tout {
  background-color: #f8f8f8;
  min-height: 9rem;
  clear: both;
  overflow: hidden;
  border-radius: .5rem;
}

.tout__avatar {
  display: block;
  width: 50%;
  margin: 2rem auto 0;
  border-radius: 50%;
}

@media screen and (min-width: 480px) {
  .tout__avatar {
    float: left;
    margin: 0 5% 0 0;
    border-radius: 0;
  }
}

@media screen and (min-width: 568px) {
  .tout__avatar {
    min-width: 9rem;
    min-height: 9rem;
    max-width: 9rem;
    max-height: 9rem;
    margin-right: 2%;
  }
}

.tout__content {
  padding: 2% 5%;
}

@media screen and (min-width: 480px) {
  .tout__content {
    padding: 0;
    float: left;
    width: 40%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 568px) {
  .tout__content {
    width: auto;
    max-width: 50%;
    margin-right: 2%;
  }
}

.tout__content__title {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: #5c5c5c;
  margin: 0;
  padding: 2rem 0 0 0;
}

.tout__content__title__detail {
  color: #a3a3a3;
  font-size: 1.2rem;
  font-weight: 400;
}

.tout__content__content {
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #5c5c5c;
  margin: 0;
  padding: 0;
}

.tout__cta {
  padding: 2% 5%;
  text-align: center;
}

@media screen and (min-width: 480px) {
  .tout__cta {
    border-top: 0.1rem solid rgba(0, 0, 0, 0.1);
    float: left;
    width: 40%;
    margin-right: 5%;
    margin-top: 1rem;
    padding: .1rem 0 0 0;
  }
}

@media screen and (min-width: 568px) {
  .tout__cta {
    border-top: none;
    width: auto;
    float: right;
    margin: .4rem 2% 0 0;
  }
}

.tout__cta__image {
  height: 1.5rem;
}

.tm-tweet-clear {
  zoom: 1;
}

.tm-tweet-clear:after {
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
  content: ".";
}

.tm-click-to-tweet {
  display: block;
  background-color: #fff;
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 15px 30px;
  margin: 15px 0px;
  zoom: 1;
}

.tm-click-to-tweet .clearfix {
  zoom: 1;
}

.tm-click-to-tweet .clearfix:after {
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
  content: ".";
}

.tm-click-to-tweet .clear {
  clear: both;
}

.tm-click-to-tweet .f-left {
  float: left;
  display: inline-block;
  position: relative;
}

.tm-click-to-tweet .f-right {
  float: right;
  display: inline-block;
  position: relative;
}

.tm-click-to-tweet .list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tm-click-to-tweet .list-reset li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tm-click-to-tweet .list-float {
  zoom: 1;
}

.tm-click-to-tweet .list-float:after {
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
  content: ".";
}

.tm-click-to-tweet .list-float li {
  float: left;
  display: inline-block;
}

.tm-click-to-tweet .kill-box-shadow {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.tm-click-to-tweet .alignright {
  float: right;
  margin-bottom: 10px;
  margin-left: 10px;
  text-align: right;
}

.tm-click-to-tweet .alignleft {
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  text-align: right;
}

.tm-click-to-tweet:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.tm-click-to-tweet .tm-ctt-reset {
  margin: 0;
  padding: 0;
  position: relative;
}

.tm-click-to-tweet:after {
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
  content: ".";
}

.tm-click-to-tweet a {
  text-decoration: none;
  text-transform: none;
}

.tm-click-to-tweet a:hover {
  text-decoration: none;
}

.tm-click-to-tweet .tm-ctt-text {
  margin: 0;
  padding: 0;
  position: relative;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.tm-click-to-tweet .tm-ctt-text a {
  margin: 0;
  padding: 0;
  position: relative;
  color: #999999;
  font-size: 24px;
  line-height: 140%;
  text-transform: none;
  letter-spacing: 0.05em;
  font-weight: 100;
  text-decoration: none;
  text-transform: none;
}

.tm-click-to-tweet .tm-ctt-text a:hover {
  text-decoration: none;
  color: #666666;
}

.tm-click-to-tweet a.tm-ctt-btn {
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
  text-transform: uppercase;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 100%;
  color: #999999;
  float: right;
  padding-right: 24px;
  text-decoration: none;
}

.tm-click-to-tweet a.tm-ctt-btn:hover {
  text-decoration: none;
  color: #666666;
  text-transform: uppercase;
}

.tm-click-to-tweet .tm-powered-by {
  font-size: 10px;
  color: #999999;
}

.tm-click-to-tweet .tm-powered-by a {
  font-size: 10px;
  color: #999999 !important;
}

.tm-click-to-tweet .tm-powered-by a:hover {
  color: #999999 !important;
  text-decoration: underline !important;
}

.gallery {
  display: block;
  margin-bottom: 6rem;
  padding-top: 6rem;
  padding-bottom: 5rem;
  border-top: 0.2rem solid #e8e8e8;
  border-bottom: 0.2rem solid #e8e8e8;
}

.gallery::after {
  clear: both;
  content: "";
  display: table;
}

.gallery__source {
  list-style: none;
  margin: 0;
  padding: 0;
}

.gallery__source li {
  display: none;
}

.gallery__full {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  top: -.8rem;
  display: block;
}

.gallery__full__item {
  display: none;
  margin: 0;
  padding: 0;
  transition: all 0.2s;
}

.gallery__full__item__img {
  width: 100%;
  border-radius: 0 0 .4rem .4rem;
}

@media screen and (min-width: 768px) {
  .gallery__full__item__img {
    border-radius: 0 0 .7rem .7rem;
  }
}

.gallery__thumbs {
  list-style: none;
  margin: 4rem auto 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

.gallery__thumbs__item {
  display: inline-block;
  border-radius: .5rem;
  border: 0.2rem solid rgba(151, 151, 151, 0.25);
  padding: .2rem;
  line-height: 0;
  width: 20%;
  max-width: 18.4rem;
  transition: all 0.2s;
}

.gallery__thumbs__item + .gallery__thumbs__item {
  margin-left: 4%;
}

@media screen and (min-width: 960px) {
  .gallery__thumbs__item + .gallery__thumbs__item {
    margin-left: 2%;
  }
}

.gallery__thumbs__item__active {
  border-color: #0393f1;
  transition: all 0.2s;
}

.gallery__thumbs__item__img {
  width: 100%;
  max-width: 18rem;
  border-radius: .5rem;
}

.gallery__browser-bar {
  width: 100%;
  height: 100%;
  border-radius: .4rem .4rem 0 0;
}

@media screen and (min-width: 768px) {
  .gallery__browser-bar {
    border-radius: .7rem .7rem 0 0;
  }
}

.search {
  display: block;
  margin-bottom: 6rem;
}

.search::after {
  clear: both;
  content: "";
  display: table;
}

.search__header {
  border-top: 0.6rem solid #242424;
  margin-bottom: 8rem;
  border-bottom: .2rem solid #e8e8e8;
}

.search__header:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 768px) {
  .search__header {
    padding-top: 6rem;
  }
}

.search__header__image {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  height: 23rem;
  margin: 1.5rem auto;
}

.search__header__image:last-child {
  margin-right: 0;
}

.search__header__image img {
  margin: 0 -100%;
  position: relative;
  top: -50%;
}

@media screen and (min-width: 768px) {
  .search__header__image {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    margin-left: 51.17883%;
    position: relative;
    top: -6.6rem;
    height: 70rem;
    left: 0.1%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .search__header__image:last-child {
    margin-right: 0;
  }
  .search__header__image img {
    top: auto;
  }
}

@media screen and (min-width: 960px) {
  .search__header__image {
    height: 50rem;
  }
}

.search__header__detail {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.search__header__detail:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .search__header__detail {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
    margin-left: -102.35765%;
  }
  .search__header__detail:last-child {
    margin-right: 0;
  }
}

.search__header__detail__type {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  padding: 0;
  margin: 0;
  color: #242424;
}

.search__header__detail__header {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.7rem;
  margin: 2.6rem 0;
  color: #242424;
}

@media screen and (min-width: 768px) {
  .search__header__detail__header {
    font-size: 4.8rem;
    line-height: 6rem;
    margin: 2rem 0;
  }
}

.search__header__detail__header a {
  color: #242424;
  text-decoration: none;
  transition: color 0.3s;
}

.search__header__detail__header a:hover {
  color: #f3732d;
}

.search__header__detail__byline {
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.8rem;
  padding: 0;
  margin: 0;
  color: #242424;
}

.search__header__detail__share-list {
  list-style: none;
  margin: 4rem 0;
  padding: 0;
}

.search__header__detail__share-list li {
  display: inline-block;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  margin-right: 1rem;
  color: #5c5c5c;
}

.search__header__detail__share-list li a {
  background: transparent no-repeat center left;
  text-decoration: none;
  line-height: 2rem;
  color: #5c5c5c;
  display: block;
}

.search__header__detail__share-list li a .svg-social-icon {
  top: 0.5rem;
  margin-right: 0.5rem;
  position: relative;
  transition: fill 0.3s;
}

.search__header__detail__share-list li a:hover {
  color: #f3732d;
}

.search__header__detail__share-list li a:hover .svg-social-icon {
  fill: #f3732d;
}

.search__content {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.search__content:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .search__content {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
    margin-left: 8.5298%;
  }
  .search__content:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .search__content {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    margin-left: 17.05961%;
  }
  .search__content:last-child {
    margin-right: 0;
  }
}

.search__content__intro {
  font-size: 2.8rem;
}

.search__results__header {
  font-weight: 300;
  font-size: 2rem;
  line-height: 2.4rem;
  margin-top: 6rem;
  padding-top: 4rem;
  border-top: .2rem solid #e8e8e8;
}

.search__results__header__section {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .5rem;
}

.search__results__highlight {
  color: #ffffff;
  background-color: #0393f1;
  padding-right: .4rem;
  padding-left: .4rem;
  border-radius: .3rem;
}

.search-header {
  padding-top: 6rem;
  padding-bottom: 0rem;
}

.search-header__content__wrapper__divider-bottom {
  border-bottom: 0.2rem solid #e8e8e8;
  padding-bottom: 0;
  margin-bottom: 0;
}

.search-header__col {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.search-header__col:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .search-header__col--title {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
  }
  .search-header__col--title:last-child {
    margin-right: 0;
  }
}

.search-header__title {
  font-weight: 300;
  color: #242424;
  margin: 0;
  font-size: 4.8rem;
  line-height: 4.8rem;
  padding: 0;
}

.search-header__title strong {
  font-weight: 700;
}

.team {
  display: block;
}

.team::after {
  clear: both;
  content: "";
  display: table;
}

.team__card {
  height: 20rem;
  border-radius: .5rem;
  background-color: #efefef;
  position: relative;
  overflow: hidden;
  margin-bottom: 1.618em;
}

@media screen and (min-width: 768px) {
  .team__card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .team__card:last-child {
    margin-right: 0;
  }
  .team__card:nth-child(3n) {
    margin-right: 0;
  }
  .team__card:nth-child(3n+1) {
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  .team__card {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .team__card:last-child {
    margin-right: 0;
  }
  .team__card:nth-child(3n) {
    margin-right: 2.35765%;
  }
  .team__card:nth-child(3n+1) {
    clear: none;
  }
  .team__card:nth-child(4n) {
    margin-right: 0;
  }
  .team__card:nth-child(4n+1) {
    clear: left;
  }
}

.team__card__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
}

.team__card__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent 0%, transparent 60%, rgba(0, 0, 0, 0.65) 100%);
}

.team__card__content {
  position: absolute;
  bottom: 0;
  padding: 1rem;
}

.team__card__content__name {
  font-weight: 600;
  font-size: 1.6rem;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.team__card__content__title {
  font-weight: 300;
  font-size: 1.6rem;
  color: #d0d0d0;
  margin: 0;
  padding: 0;
}

.team__card__content__networks {
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0;
}

.team__card__content__networks__item {
  display: block;
  float: left;
  margin: 0 1rem 0 0;
  padding: 0;
}

.team__card__content__networks__item__linkedin, .team__card__content__networks__item__twitter, .team__card__content__networks__item__medium, .team__card__content__networks__item__github, .team__card__content__networks__item__dribbble, .team__card__content__networks__item__website {
  display: block;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: #ffffff;
}

.team__card__content__networks__item__linkedin span, .team__card__content__networks__item__twitter span, .team__card__content__networks__item__medium span, .team__card__content__networks__item__github span, .team__card__content__networks__item__dribbble span, .team__card__content__networks__item__website span {
  display: none;
}

.answers-section {
  background-color: #f7f6f1;
}

.answers-section__tabs {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.answers-section__tabs::after {
  clear: both;
  content: "";
  display: table;
}

.answers-section__tabs::after {
  clear: both;
  content: "";
  display: table;
}

.answers-section__tabs__list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.answers-section__tabs__list__item {
  background: none;
  float: left;
  display: block;
  padding: 1rem 0;
  width: 50%;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.8rem;
  color: #a3a3a3;
}

@media screen and (min-width: 568px) {
  .answers-section__tabs__list__item {
    width: 18rem;
    font-size: 2.4rem;
  }
}

.answers-section__tabs__list__item--active {
  background-color: #f7f6f1;
  color: #242424;
}

.answers-section__tabs__list__item--active:first-child {
  border-top-right-radius: .5rem;
}

.answers-section__tabs__list__item--active:last-child {
  border-top-left-radius: .5rem;
}

@media screen and (min-width: 568px) {
  .answers-section__tabs__list__item--active {
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
  }
}

.answers-section__content {
  background-color: #f7f6f1;
  padding-right: 20px;
  padding-left: 20px;
}

@media screen and (min-width: 1210px) {
  .answers-section__content {
    padding-right: 0;
    padding-left: 0;
  }
}

.answers-section__content__section {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}

.answers-section__content__section::after {
  clear: both;
  content: "";
  display: table;
}

.answers-section__content__section::after {
  clear: both;
  content: "";
  display: table;
}

.answers-section__content__main {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  padding: 3rem 0 0 0;
  display: none;
}

.answers-section__content__main:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .answers-section__content__main {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    display: none;
  }
  .answers-section__content__main:last-child {
    margin-right: 0;
  }
}

.answers-section__content__main--active {
  display: inherit;
}

.answers-section__content__aside {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  padding: 3rem 0 0 0;
}

.answers-section__content__aside:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .answers-section__content__aside {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .answers-section__content__aside:last-child {
    margin-right: 0;
  }
}

.qa-block {
  margin-bottom: 30px;
}

.qa-block:last-of-type {
  margin-bottom: 0;
}

.qa-block__question {
  padding: 30px 30px 0;
  background-color: #ffffff;
}

.qa-block__question__title, .qa-block__question__title--inverse {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: #3a424c;
  margin: 0;
  padding: 0;
}

.qa-block__question__title--inverse {
  color: #ffffff;
}

.qa-block__question__asked {
  opacity: 0.79;
  font-weight: 400;
  font-size: 1.4rem;
  color: #848484;
  letter-spacing: 0.034rem;
  padding: 1rem 0 .8rem 0;
}

.qa-block__meta {
  padding: 20px 30px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid #e2e2e2;
  overflow: hidden;
}

.qa-block__meta__detail {
  font-size: 1.4rem;
  line-height: 3rem;
  color: rgba(96, 96, 96, 0.75);
  font-weight: 400;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.qa-block__meta__detail--inverse {
  color: rgba(255, 255, 255, 0.6);
}

.qa-block__meta__detail__count {
  font-weight: 600;
  color: #606060;
}

.qa-block__meta__detail__count--inverse {
  color: rgba(255, 255, 255, 0.6);
}

.qa-block__meta__image__list {
  display: inline-block;
  list-style: none;
  margin: 0 .5rem;
  padding: 0;
}

.qa-block__meta__image__list__item {
  position: relative;
  display: inline-block;
  top: .6rem;
  margin: 0 .4rem 0 0;
  padding: 0;
}

.qa-block__meta__image__list__item:last-child {
  margin-right: 0;
}

.qa-block__meta__image__list__item img {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: .4rem;
}

.qa-block__answer {
  padding: 30px;
  position: relative;
  background-color: #ffffff;
}

.qa-block__answer__expert {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.qa-block__answer__expert__photo {
  display: block;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-right: 1rem;
  position: relative;
}

.qa-block__answer__expert__detail {
  line-height: 0;
}

.qa-block__answer__expert__detail__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #242424;
  display: block;
  margin-bottom: 2px;
}

.qa-block__answer__expert__detail__title {
  font-family: "chaparral-pro", Georgia, "Times New Roman", serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #5c5c5c;
  margin-bottom: 1px;
}

.qa-block__answer__expert__detail__cta {
  display: block;
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 1.6rem;
  text-transform: uppercase;
  font-weight: 700;
  padding-left: 20px;
  background: transparent url("/assets/images/ui/icon-phone.svg") no-repeat left center;
  text-decoration: none;
  color: #ff5629;
  transition: color 0.2s;
}

.qa-block__answer__expert__detail__cta:hover, .qa-block__answer__expert__detail__cta:focus {
  color: #ff9276;
}

.qa-block__answer__detail {
  font-family: "chaparral-pro", Georgia, "Times New Roman", serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #3a424c;
  margin: 0;
  padding: 0;
}

.qa-block__answer__detail--summary {
  font-family: "chaparral-pro", Georgia, "Times New Roman", serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #3a424c;
  margin: 0;
  padding: 0;
  padding-top: 1.5rem;
}

.qa-block__answer__detail p,
.qa-block__answer__detail li {
  font-family: "chaparral-pro", Georgia, "Times New Roman", serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #3a424c;
}

.qa-block__answer__detail p a,
.qa-block__answer__detail li a {
  text-decoration: none;
  color: #ff5629;
  transition: color 0.2s;
}

.qa-block__answer__detail p a:hover, .qa-block__answer__detail p a:focus,
.qa-block__answer__detail li a:hover,
.qa-block__answer__detail li a:focus {
  color: #ff9276;
}

.aside-block {
  background-color: #ffffff;
  margin-bottom: 30px;
}

.aside-block__header {
  font-weight: 700;
  font-size: 14px;
  color: #1d181f;
  letter-spacing: 1.47px;
  line-height: 21px;
  text-transform: uppercase;
  padding: 20px;
  margin: 0;
}

.aside-block__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.aside-block__list__item {
  font-weight: 400;
  padding: 0 20px 15px;
  margin-bottom: 15px;
  font-family: "chaparral-pro", Georgia, "Times New Roman", serif;
  font-size: 18px;
  color: #3A424C;
  line-height: 21px;
  border-bottom: 1px solid rgba(178, 157, 132, 0.31);
}

.aside-block__list__item:last-of-type {
  margin-bottom: 0;
  border-bottom: 0;
}

.aside-block__list__item a {
  display: block;
}

.featured-experts__expert {
  overflow: hidden;
  padding: 0 20px 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.featured-experts__expert__photo {
  display: block;
  float: left;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  margin-right: 10px;
}

.featured-experts__expert__detail__name {
  font-weight: 700;
  font-size: 16px;
  color: #ff5629;
  line-height: 24px;
  margin: 0 0 3px 0;
  padding: 0;
}

.featured-experts__expert__detail__title {
  font-family: "chaparral-pro", Georgia, "Times New Roman", serif;
  font-size: 15px;
  color: rgba(132, 132, 132, 0.8);
  line-height: 13px;
  margin: 0 0 3px 0;
  padding: 0;
}

.featured-experts__expert__detail__company {
  margin: 0;
  padding: 0;
}

.featured-experts__expert__name {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #ff5629;
  transition: color 0.3s;
}

a:hover .featured-experts__expert__name {
  color: #f3732d;
}

.featured-experts__expert__summary {
  font-weight: 400;
  font-size: 1.3rem;
  color: #5C5C5C;
  line-height: 1.8rem;
  margin: 0;
  padding: 0;
  transition: color 0.3s;
}

a:hover .featured-experts__expert__summary {
  color: #f3732d;
}

.category-label {
  display: inline-block;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.246rem;
  line-height: 0;
  letter-spacing: .174rem;
  color: #3a424c;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  padding: 13px 9px 10px 10px;
  border: 2px solid rgba(58, 66, 76, 0.3);
}

.category-label--inverse {
  display: inline-block;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.246rem;
  line-height: 0;
  letter-spacing: .174rem;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  padding: 13px 9px 10px 10px;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.taxonomy-list {
  list-style: none;
  margin: 0;
  padding: 0 20px 10px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.taxonomy-list__item {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.246rem;
  line-height: 0;
  letter-spacing: .174rem;
  color: #3a424c;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  margin-right: 10px;
  margin-bottom: 10px;
}

.taxonomy-list__item a {
  display: block;
  color: #898d93;
  padding: 13px 9px 10px 10px;
  border: 2px solid rgba(58, 66, 76, 0.3);
  transition: color 0.2s, border-color 0.2s;
}

.taxonomy-list__item a:hover {
  color: #ff5629;
  border-color: #ff5629;
}

.search-answers {
  padding: 40px 20px;
}

.search-answers__input {
  border: 0;
  outline: 0;
  width: 100%;
  text-indent: 30px;
  background: transparent url("/assets/images/ui/icon-search.svg") no-repeat left center;
}

.search-answers__input::-webkit-input-placeholder {
  color: #b0b3b7;
}

.search-answers__input::-moz-placeholder {
  color: #b0b3b7;
}

.search-answers__input:-ms-input-placeholder {
  color: #b0b3b7;
}

.search-answers__input:-moz-placeholder {
  color: #b0b3b7;
}

.related-questions {
  background-color: #ffffff;
  padding: 30px;
  margin-bottom: 30px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.related-questions__header {
  font-weight: 700;
  font-size: 14px;
  color: #1d181f;
  letter-spacing: 1.47px;
  line-height: 21px;
  text-transform: uppercase;
  padding-bottom: 20px;
  margin: 0;
}

.related-questions__header {
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
}

.related-questions__question {
  -webkit-flex-basis: 100%;
      -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
}

@media all and (min-width: 600px) {
  .related-questions__question {
    -webkit-flex-basis: 33.3333%;
        -ms-flex-preferred-size: 33.3333%;
            flex-basis: 33.3333%;
  }
  .related-questions__question:nth-of-type(2n) {
    -webkit-flex-basis: 33.3334%;
        -ms-flex-preferred-size: 33.3334%;
            flex-basis: 33.3334%;
  }
}

.related-questions__question p {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  color: #3a424c;
  line-height: 2.1rem;
  padding-right: 20px;
}

.related-questions__question p a {
  text-decoration: none;
  color: #ff5629;
  transition: color 0.2s;
}

.related-questions__question p a:hover, .related-questions__question p a:focus {
  color: #ff9276;
}

.platform {
  display: block;
  padding-top: 5rem;
}

.platform::after {
  clear: both;
  content: "";
  display: table;
}

.platform__header {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.platform__header__description {
  text-transform: uppercase;
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;
}

.platform__header__logo {
  height: 6rem;
  max-width: 30rem;
  position: relative;
  top: -.5rem;
}

.platform__header .col {
  box-sizing: border-box;
  float: left;
  display: block;
  margin-right: 3.57866%;
  width: 100%;
}

.platform__header .col:last-child {
  margin-right: 0;
}

@media screen and (min-width: 568px) {
  .platform__header .col {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    padding-right: 2rem;
  }
  .platform__header .col:last-child {
    margin-right: 0;
  }
  .platform__header .col h2 {
    text-align: right;
  }
  .platform__header .col:nth-child(2n) {
    padding-left: 2rem;
    margin-right: 0;
  }
  .platform__header .col:nth-child(2n) h2 {
    text-align: left;
  }
}

.platform__header .col .svg-product-logo {
  width: auto;
  margin-bottom: auto;
  fill: #555555;
  position: relative;
}

a .platform__header .col .svg-product-logo {
  transition: fill 0.2s;
}

.platform__header .col .svg-product-logo.clarity {
  max-height: 3rem;
}

a:hover .platform__header .col .svg-product-logo.clarity {
  fill: #61bed9;
}

.platform__header .col .svg-product-logo.fundable {
  max-height: 2rem;
  top: 0.4rem;
}

a:hover .platform__header .col .svg-product-logo.fundable {
  fill: #242424;
}

.platform__header .col .svg-product-logo.killerstartups {
  max-height: 3rem;
}

a:hover .platform__header .col .svg-product-logo.killerstartups {
  fill: #0597d2;
}

.platform__header .col .svg-product-logo.launchrock {
  max-height: 2rem;
  top: 0.35rem;
}

a:hover .platform__header .col .svg-product-logo.launchrock {
  fill: #dc0000;
}

.platform__family {
  width: 100%;
  padding: 3rem 0;
}

@media screen and (min-width: 768px) {
  .platform__family {
    padding: 4rem 0;
  }
}

.platform__family .col {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
  text-align: center;
}

.platform__family .col:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .platform__family .col {
    text-align: left;
    float: left;
    display: block;
    margin-right: 5.85151%;
    width: 15.3188%;
  }
  .platform__family .col:last-child {
    margin-right: 0;
  }
}

.platform__family p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  padding: 0;
  position: relative;
  color: #242424;
  top: -.5rem;
}

.platform__family a {
  color: #898989;
}

.platform__family a:hover {
  color: #0393f1;
}

.platform__family a:hover .svg-platform-logo {
  fill: #0393f1;
}

.platform__family img {
  max-width: 100%;
  height: 5rem;
}

.platform__family .svg-platform-logo {
  position: relative;
}

.highlight {
  font-size: 1rem;
  padding-top: 5rem;
}

.highlight:last-of-type {
  margin-bottom: 6.472em;
}

.highlight__header {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.highlight__header__description {
  text-transform: uppercase;
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;
}

.highlight__header__logo {
  height: 6rem;
  max-width: 30rem;
  position: relative;
  top: -.5rem;
}

.highlight section {
  display: block;
}

.highlight section::after {
  clear: both;
  content: "";
  display: table;
}

.highlight section.blocks {
  padding-top: 1rem;
  padding-bottom: 5rem;
  margin-bottom: -1.618em;
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.highlight section.blocks:last-child {
  margin-right: 0;
}

@media screen and (min-width: 568px) {
  .highlight section.blocks {
    float: left;
    display: block;
    margin-right: 3.57866%;
    width: 100%;
  }
  .highlight section.blocks:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .highlight section.blocks {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  }
  .highlight section.blocks:last-child {
    margin-right: 0;
  }
}

.highlight section.blocks .row .col:last-of-type {
  margin-right: 0;
}

.highlight section.blocks .row .col.one-wide {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.highlight section.blocks .row .col.one-wide:last-child {
  margin-right: 0;
}

@media screen and (min-width: 568px) {
  .highlight section.blocks .row .col.one-wide {
    float: left;
    display: block;
    margin-right: 3.57866%;
    width: 22.316%;
  }
  .highlight section.blocks .row .col.one-wide:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .highlight section.blocks .row .col.one-wide {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .highlight section.blocks .row .col.one-wide:last-child {
    margin-right: 0;
  }
}

.highlight section.blocks .row .col.two-wide {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.highlight section.blocks .row .col.two-wide:last-child {
  margin-right: 0;
}

@media screen and (min-width: 568px) {
  .highlight section.blocks .row .col.two-wide {
    float: left;
    display: block;
    margin-right: 3.57866%;
    width: 48.21067%;
  }
  .highlight section.blocks .row .col.two-wide:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .highlight section.blocks .row .col.two-wide {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .highlight section.blocks .row .col.two-wide:last-child {
    margin-right: 0;
  }
}

.highlight section.blocks .row .col.two-wide .two-wide {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.highlight section.blocks .row .col.two-wide .two-wide.top:nth-of-type(2n) {
  margin-right: 0;
}

.highlight section.blocks .row .col.two-wide .two-wide.bottom:nth-of-type(3n) {
  margin-right: 0;
}

.highlight section.blocks .row .col.two-wide .two-wide:last-child {
  margin-right: 0;
}

@media screen and (min-width: 568px) {
  .highlight section.blocks .row .col.two-wide .two-wide {
    float: left;
    display: block;
    margin-right: 3.57866%;
    width: 48.21067%;
  }
  .highlight section.blocks .row .col.two-wide .two-wide:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .highlight section.blocks .row .col.two-wide .two-wide {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .highlight section.blocks .row .col.two-wide .two-wide:last-child {
    margin-right: 0;
  }
}

.highlight section.blocks .row .col.three-wide {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 100%;
}

.highlight section.blocks .row .col.three-wide:last-child {
  margin-right: 0;
}

@media screen and (min-width: 568px) {
  .highlight section.blocks .row .col.three-wide {
    float: left;
    display: block;
    margin-right: 3.57866%;
    width: 74.10533%;
  }
  .highlight section.blocks .row .col.three-wide:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .highlight section.blocks .row .col.three-wide {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
  }
  .highlight section.blocks .row .col.three-wide:last-child {
    margin-right: 0;
  }
}

.highlight section.blocks .row .col .block {
  height: 20em;
  margin-bottom: 1.618em;
  padding: 2rem;
}

.highlight section.blocks .row .col .block.tall {
  height: 20em;
}

@media screen and (min-width: 960px) {
  .highlight section.blocks .row .col .block.tall {
    height: 41.618em;
  }
}

.highlight {
  display: block;
}

.highlight::after {
  clear: both;
  content: "";
  display: table;
}

.highlight h2 {
  margin: 0;
  padding: 0;
}

.highlight p {
  font-size: 2rem;
  line-height: 2.2rem;
  margin: 1rem 0 0 0;
  padding: 0;
}

.highlight .block {
  background-color: #e5e5e5;
  background-size: cover;
  background-position: 50% 50%;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  border-radius: .5rem;
}

.highlight .block .content.top {
  position: absolute;
  top: 2rem;
  padding-right: 2rem;
}

.highlight .block .content.center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.highlight .block .content.bottom {
  position: absolute;
  bottom: 2rem;
  padding-right: 2rem;
}

.highlight.one .header h2 a {
  color: #7d7d7d;
  border-bottom: 0.125rem solid #7d7d7d;
  transition: color 0.3s, border-color 0.3s;
}

.highlight.one .header h2 a:hover.launchrock {
  color: #dc0000;
  border-bottom: 0.125rem solid #dc0000;
}

.highlight.one .header h2 a:hover.killerstartups {
  color: #0597d2;
  border-bottom: 0.125rem solid #0597d2;
}

.highlight.one .header h2 a:hover.fundable {
  color: #242424;
  border-bottom: 0.125rem solid #242424;
}

.highlight.one .header h2 a:hover.clarity {
  color: #61bed9;
  border-bottom: 0.125rem solid #61bed9;
}

.highlight.one .block.b1 {
  background-color: #242424;
}

.highlight.one .block.b1 h2, .highlight.one .block.b1 h3, .highlight.one .block.b1 p {
  color: #ffffff;
}

.highlight.one .block.b2 {
  background-color: #0393f1;
}

.highlight.one .block.b2 h2, .highlight.one .block.b2 h3, .highlight.one .block.b2 p {
  color: #ffffff;
}

.highlight.one .block.b3 {
  background-image: url("/assets/images/226.jpg");
}

.highlight.one .block.b5 {
  background-image: url("/assets/images/225.jpg");
}

.highlight.one .block.b5 h2, .highlight.one .block.b5 h3, .highlight.one .block.b5 p {
  color: #ffffff;
  line-height: 3.5rem;
}

.highlight.one .block.b5 h2 span, .highlight.one .block.b5 h3 span, .highlight.one .block.b5 p span {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-box-decoration-break: clone;
  -ms-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.75), -1rem 0 0 rgba(0, 0, 0, 0.75);
}

.highlight.one .block.b5 h2 {
  text-transform: uppercase;
  margin-bottom: 0.65rem;
}

.highlight.one .block.b5 h3 span {
  color: #0393f1;
  font-weight: 400;
}

.highlight.two .block.b1 {
  background-color: #fc6620;
  background-image: url("/assets/images/95.png");
  background-size: auto;
  background-repeat: repeat;
}

.highlight.two .block.b1 h2, .highlight.two .block.b1 h3, .highlight.two .block.b1 p {
  color: #ffffff;
}

.highlight.two .block.b2 {
  background-color: #0393f1;
}

.highlight.two .block.b2 h2, .highlight.two .block.b2 h3, .highlight.two .block.b2 p {
  color: #ffffff;
}

.highlight.two .block.b4 {
  background-image: url("/assets/images/hero-about.jpg");
}

.highlight.two .block.b6 {
  background-image: url("/assets/images/237.jpg");
  clear: both;
}

.highlight.two .block.b6 h2, .highlight.two .block.b6 h3, .highlight.two .block.b6 p {
  color: #ffffff;
  line-height: 3.5rem;
}

.highlight.two .block.b6 h2 span, .highlight.two .block.b6 h3 span, .highlight.two .block.b6 p span {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-box-decoration-break: clone;
  -ms-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.75), -1rem 0 0 rgba(0, 0, 0, 0.75);
}

.highlight.two .block.b6 h2 {
  text-transform: uppercase;
  margin-bottom: 0.65rem;
}

.highlight.two .block.b6 h3 span {
  color: #0393f1;
  font-weight: 400;
}

.highlight.three .block.b1 {
  background-color: #0393f1;
}

.highlight.three .block.b1 h2, .highlight.three .block.b1 h3, .highlight.three .block.b1 p {
  color: #ffffff;
}

.highlight.three .block.b3 {
  background-color: #242424;
}

.highlight.three .block.b3 h2, .highlight.three .block.b3 h3, .highlight.three .block.b3 p {
  color: #ffffff;
  text-align: center;
}

.highlight.three .block.b3 p span {
  text-transform: uppercase;
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
}

.highlight.three .block.b3 p.fact span {
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #ffffff;
  display: inline-block;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0.125rem solid #ffffff;
  padding: 0.15rem 0.5rem;
  margin: 0 auto 1rem auto;
}

.highlight.three .block.b4 {
  background-image: url("/assets/images/240.jpg");
}

.highlight.three .block.b4 h2, .highlight.three .block.b4 h3, .highlight.three .block.b4 p {
  color: #ffffff;
}

.highlight.four .block.b1 {
  background-color: #242424;
}

.highlight.four .block.b1 h2, .highlight.four .block.b1 h3, .highlight.four .block.b1 p {
  color: #ffffff;
}

.highlight.four .block.b2 {
  background-image: url("/assets/images/211.jpg");
}

.highlight.four .block.b3 {
  background-color: #0393f1;
}

.highlight.four .block.b3 h2, .highlight.four .block.b3 h3, .highlight.four .block.b3 p {
  color: #ffffff;
}

.highlight.four .block.b4 {
  background-image: url("/assets/images/219.jpg");
}

.highlight.four .block.b4 h2, .highlight.four .block.b4 h3, .highlight.four .block.b4 p {
  color: #ffffff;
  line-height: 3.5rem;
}

.highlight.four .block.b4 h2 span, .highlight.four .block.b4 h3 span, .highlight.four .block.b4 p span {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-box-decoration-break: clone;
  -ms-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.75), -1rem 0 0 rgba(0, 0, 0, 0.75);
}

.highlight.four .block.b4 h2 {
  text-transform: uppercase;
  margin-bottom: 0.65rem;
}

.highlight.four .block.b4 h3 span {
  color: #0393f1;
  font-weight: 400;
}

.highlight.five .block.b1 {
  background-color: #0393f1;
}

.highlight.five .block.b1 h2, .highlight.five .block.b1 h3, .highlight.five .block.b1 p {
  color: #ffffff;
}

.highlight.five .block.b3 {
  background-color: #242424;
}

.highlight.five .block.b3 h2, .highlight.five .block.b3 h3, .highlight.five .block.b3 p {
  color: #ffffff;
}

.highlight.five .block.b3 p.fact span {
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #ffffff;
  display: inline-block;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0.125rem solid #ffffff;
  padding: 0.15rem 0.5rem;
  margin: 0 auto 1rem auto;
}

.highlight.five .block.b4 {
  background-image: url("/assets/images/3631.jpg");
}

.highlight.five .block.b5 {
  background-image: url("/assets/images/239.jpg");
}

.highlight.five .block.b5 h2, .highlight.five .block.b5 h3, .highlight.five .block.b5 p {
  color: #ffffff;
}

.highlight.five .block.b6 {
  background-image: url("/assets/images/235.jpg");
}

.highlight.five .block.b6 h2, .highlight.five .block.b6 h3, .highlight.five .block.b6 p {
  color: #ffffff;
  line-height: 3.5rem;
}

.highlight.five .block.b6 h2 span, .highlight.five .block.b6 h3 span, .highlight.five .block.b6 p span {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-box-decoration-break: clone;
  -ms-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.75), -1rem 0 0 rgba(0, 0, 0, 0.75);
}

.highlight.five .block.b6 h2 {
  text-transform: uppercase;
  margin-bottom: 0.65rem;
}

.highlight.five .block.b6 h3 span {
  color: #0393f1;
  font-weight: 400;
}

.highlight.six .block.b1 {
  background-color: #fc6620;
  background-image: url("/assets/images/206.jpg");
}

.highlight.six .block.b1 h2, .highlight.six .block.b1 h3, .highlight.six .block.b1 p {
  color: #ffffff;
}

.highlight.six .block.b2 {
  background-image: url("/assets/images/211.jpg");
}

.highlight.six .block.b4 {
  background-image: url("/assets/images/202.jpg");
}

.section--demo {
  padding: 10rem 0;
  border-top: 0.1rem solid #cccccc;
}

.section--demo__header {
  text-align: center;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

.section--demo__header::after {
  clear: both;
  content: "";
  display: table;
}

.section--demo__header__header {
  font-weight: 100;
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.section--demo__header__description {
  font-family: "chaparral-pro", Georgia, "Times New Roman", serif;
  font-style: italic;
  margin: 0;
  padding: 0;
}

.section--demo__label {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: block;
  border-bottom: 0.1rem solid #cccccc;
  border-collapse: collapse;
}

.section--demo__label::after {
  clear: both;
  content: "";
  display: table;
}

.section--demo__label span {
  position: relative;
  top: 1px;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.4rem 0.6rem;
  border: 0.1rem solid #cccccc;
  border-bottom: none;
  margin-right: 2rem;
  color: #3e3e3e;
  background-color: #eeeeee;
  border-collapse: collapse;
}

section section.example {
  float: left;
  display: block;
  margin-right: 7.42297%;
  width: 314.84594%;
}

section section.example:last-child {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  section section.example {
    float: left;
    display: block;
    margin-right: 7.42297%;
    width: 234.27871%;
  }
  section section.example:last-child {
    margin-right: 0;
  }
}

section code {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-size: 1.4rem;
}

section.demo__hero {
  padding-top: 60px;
  padding-bottom: 40px;
}

section.demo__hero .logo {
  max-width: 100px;
}

section.demo__hero h1 {
  margin: 0;
}

section.element {
  padding: 0 2rem;
}

section.element, section.component {
  padding-top: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #cccccc;
}

section .header {
  text-align: center;
  margin-bottom: 3rem;
}

section .header h1 {
  font-weight: 100;
}

section .header h2 {
  font-weight: 100;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

section .header p {
  font-family: "chaparral-pro", Georgia, "Times New Roman", serif;
  font-style: italic;
  margin: 0;
  padding: 0;
}

section .header ul {
  list-style: none;
  margin: 4rem 0;
  padding: 0;
}

section .header ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
}

section .header ul li a {
  padding: 1em;
  border: 1px solid transparent;
}

section .header ul li a.active {
  color: #f3732d;
  border: 1px solid #f3732d;
  border-radius: 0.5rem;
}

section label.demo {
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: block;
  border-bottom: 0.1rem solid #cccccc;
  border-collapse: collapse;
}

section label.demo span {
  position: relative;
  top: 1px;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.4rem 0.6rem;
  border: 0.1rem solid #cccccc;
  border-bottom: none;
  margin-right: 2rem;
  color: #3e3e3e;
  background-color: #eeeeee;
  border-collapse: collapse;
}

section ul.states {
  list-style: none;
  margin: 0;
  padding: 0;
}

section ul.states li {
  margin: 0 0 0 0;
  padding: 0;
}

section ul.states li code {
  font-size: 1.4rem;
  white-space: normal;
}

section ul.requires {
  list-style: none;
  margin: 0;
  padding: 0;
}

section ul.requires li {
  margin: 0 0 0 0;
  padding: 0;
}

section ul.requires li code {
  font-size: 1.4rem;
  white-space: normal;
}

.hljs {
  padding: 0;
}
